var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"w-100",attrs:{"show":_vm.show,"rounded":"sm"}},[_c('div',{staticClass:"questionnaire-container pb-3 pt-2"},[_c('survey-header'),_c('b-row',{staticClass:"justify-content-between no-gutters m-auto mb-2",staticStyle:{"max-width":"90%"}},[_c('div',{staticClass:"w-100"},[_c('b-progress',{staticClass:"mt-2 mb-3 questionnaire-progress",attrs:{"value":_vm.questionnaireProgress,"max":"100","variant":"mhc-dark","height":".5rem","width":"100%"}})],1)]),_c('div',{staticClass:"questionBox"},[(_vm.page == 0)?_c('pre-questionnaire',{attrs:{"page":_vm.page}}):_vm._e(),(_vm.page == 1)?_c('div',[(
            _vm.currentQuestionnaire.questions &&
            _vm.questionIndex < _vm.currentQuestionnaire.questions.length
          )?_c('div',{key:_vm.questionIndex},[_c('h3',{staticClass:"text-center m-auto question-title",staticStyle:{"max-width":"60ch"}},[_vm._v(" "+_vm._s(_vm.currentQuestionnaire.questions[_vm.questionIndex].question_text)+" ")]),_c('div',{staticClass:"text-center pb-2"},[(
                _vm.currentQuestionnaire.questions[_vm.questionIndex]
                  .multiple_answers == 0
              )?_c('p',[_vm._v(" Select one option ")]):_c('p',[_vm._v("Select all options that apply")]),_c('hr',{staticClass:"mhc-divider m-auto"})]),_c('b-form-checkbox-group',{class:_vm.currentQuestionnaire.questions[_vm.questionIndex]
                .is_scale === 0
                ? 'multiple-answers'
                : 'multiple-answers scale-number-container',attrs:{"name":"multiple-answers"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.currentQuestionnaire.questions[
                _vm.questionIndex
              ].answers),function(answer,index){return _c('div',{key:index},[(
                  _vm.currentQuestionnaire.questions[_vm.questionIndex]
                    .is_scale == 0
                )?_c('div',{staticClass:"questionContainer"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"optionContainer"},[_c('div',{staticClass:"option"},[(
                          _vm.currentQuestionnaire.questions[_vm.questionIndex]
                            .multiple_answers == 0
                        )?_c('div',{on:{"click":function($event){return _vm.selectOption(answer)}}},[_c('span',{class:_vm.final[_vm.questionIndex] &&
                            _vm.final[_vm.questionIndex].answers.includes(answer.id)
                              ? 'option-click bg-light-green'
                              : 'option-click'},[_vm._v(" "+_vm._s(answer.answer_text)+" ")])]):_c('div',[_c('b-form-checkbox',{key:answer.id,staticClass:"w-100 p-0 multiple-answer",attrs:{"value":answer},on:{"change":function($event){return _vm.setQuestionId(_vm.questionIndex)}}},[_c('span',{staticClass:"option-click"},[_vm._v(" "+_vm._s(answer.answer_text)+" ")])])],1)])])])]):_c('div',{on:{"click":function($event){return _vm.selectOption(answer)}}},[_c('span',{class:_vm.final[_vm.questionIndex] &&
                    _vm.final[_vm.questionIndex].answers.includes(answer.id)
                      ? 'scale-option bg-light-green'
                      : 'scale-option'},[_vm._v(" "+_vm._s(_vm._f("removeScaleText")(answer.answer_text))+" ")])])])}),(
                  _vm.currentQuestionnaire.questions[_vm.questionIndex].type.id == 3 || _vm.currentQuestionnaire.questions[_vm.questionIndex].type.id == 4
                )?_c('div',{staticClass:"questionContainer"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"optionContainer"},[_c('div',{staticClass:"option"},[_c('b-form-checkbox',{staticClass:"w-100 p-0 multiple-answer",on:{"change":function($event){return _vm.clickedNoAnswer(_vm.questionIndex)}}},[(_vm.selected.length == 0)?_c('span',{class:_vm.answerSelected == true
                                ? 'option-click bg-light-green'
                                : 'option-click'},[_vm._v(" No I haven’t ")]):_c('span',{class:_vm.selected.length == 1 && _vm.selected[0] == null
                                ? 'option-click bg-light-green'
                                : 'option-click'},[_vm._v(" No I haven’t ")])])],1)])])]):_vm._e()],2),(_vm.currentQuestionnaire.questions[_vm.questionIndex].is_scale === 1)?_c('div',{staticClass:"scale-container mt-2 mt-sm-0"},[_c('div',[_c('small',[_vm._v("1 = Strongly Disagree")])]),_c('div',[_c('small',[_vm._v("10 = Strongly Agree")])])]):_vm._e(),_c('div',{staticClass:"questionContainer"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"optionContainer"},[_c('footer',{staticClass:"questionFooter"},[_c('nav',{staticClass:"pagination",attrs:{"role":"navigation","aria-label":"pagination"}},[(
                        _vm.currentQuestionnaire.questions[_vm.questionIndex]
                          .multiple_answers == 1 && _vm.questionIndex + 1 < _vm.currentQuestionnaire.questions.length
                      )?_c('div',{staticClass:"option w-100"},[(this.selected.length == 0)?_c('b-button',{staticClass:"option-click confirm-btn",attrs:{"variant":"primary","block":"","disabled":_vm.answerSelected == false},on:{"click":function($event){return _vm.confirmMultipleAnswer(
                            _vm.currentQuestionnaire.questions[_vm.questionIndex]
                              .type,
                            _vm.currentQuestionnaire.questions[_vm.questionIndex].id
                          )}}},[_vm._v(" Confirm answers ")]):_c('b-button',{staticClass:"option-click confirm-btn",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.confirmMultipleAnswer(
                            _vm.currentQuestionnaire.questions[_vm.questionIndex]
                              .type,
                            _vm.currentQuestionnaire.questions[_vm.questionIndex].id
                          )}}},[_vm._v(" Confirm answers ")])],1):_vm._e()])])])])]),_c('div',{staticClass:"questionContainer"},[_c('div',{staticClass:"d-flex"},[(_vm.currentQuestionnaire.subcategory.questionnairecategory.name == 'My mental health and my life situation(s)')?_c('div',{staticClass:"optionContainer"},[(_vm.questionIndex + 1 == _vm.currentQuestionnaire.questions.length)?_c('b-button',{attrs:{"block":"","variant":"primary","size":"lg","disabled":this.selected.length == 0 && this.answerSelected == false},on:{"click":function($event){return _vm.submitResults(_vm.currentQuestionnaire.questions[_vm.questionIndex].type, _vm.currentQuestionnaire.questions[_vm.questionIndex].id)}}},[_vm._v("Submit Results")]):_vm._e()],1):_c('div',{staticClass:"optionContainer"},[(_vm.questionIndex + 1 == _vm.currentQuestionnaire.questions.length)?_c('b-button',{attrs:{"block":"","variant":"primary","size":"lg","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.submitEmotionalResults(_vm.currentQuestionnaire.questions[_vm.questionIndex].type, _vm.currentQuestionnaire.questions[_vm.questionIndex].id)}}},[_vm._v("Submit Results")]):_vm._e()],1)])])],1):_vm._e()]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }