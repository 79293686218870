<template>
  <b-row
    class="justify-content-between no-gutters m-auto mb-2"
    style="max-width: 90%"
  >
    <b-col md="2" class="text-left col-6">
      <b-link
        class="header-pagination"
        v-on:click="prev"
        v-if="questionIndex > 0"
      >
        <feather-icon icon="ArrowLeftIcon" size="18" />
        Previous</b-link
      >
    </b-col>

    <b-col md="8" class="col-12 mhc-header-title">
      <b-row class="no-gutters">
        <h3 class="m-auto color-mhc-dark">
          {{ currentQuestionnaire.title }}
        </h3>
      </b-row>

      <b-row class="no-gutters">
        <p class="m-auto text-center">
          <!--<span v-if="currentQuestionnaire.subcategory != undefined">{{ currentQuestionnaire.subcategory.name }}</span> has <span v-if="currentQuestionnaire.questions">
          {{ currentQuestionnaire.questions.length }} questions. You are free to
          stop whenever you choose.</span>-->
          You are free to stop this mental health check whenever you choose
        </p>
      </b-row>

      <b-row class="no-gutters mt-05" v-if="currentQuestionnaire.professional != null">
        <div class="d-flex justify-center items-center mx-auto cursor-pointer" @click="openProfessionalProfileModal()">
          <img src="/verified.png" class="mr-05" /> 
          <small class="text-gray">Reviewed by {{currentQuestionnaire.professional.first_name}} {{currentQuestionnaire.professional.last_name}}</small>
        </div>

        <professional-profile :professional="currentQuestionnaire.professional" />
      </b-row>
    </b-col>

    <b-col md="2" class="text-right col-6 mhc-header-stop">
      <b-link
        class="header-pagination"
        v-if="questionIndex > 0"
        v-b-modal.modal-center
      >
        Stop
      </b-link>

      <b-modal
        id="modal-center"
        ref="stop-modal"
        hide-footer
        hide-header
        centered
        size="lg"
      >
        <div class="py-4">
          <div class="d-block text-center">
            <h2>You're doing really well. Keep going!</h2>
            <p style="margin: auto; max-width: 65ch">
              Others have also experienced the feeling of wanting to end their
              mental health check but powered through and are glad they did.
            </p>
            <p class="mt-2">You can do it too but it’s your choice.</p>
            <hr class="mhc-divider m-auto" />
          </div>
          <div class="d-block text-center">
            <p class="mt-1" style="color: black">
              Would you like to continue your Mental Health Check?
            </p>
            <b-button
              variant="primary"
              size="lg"
              class="mt-2"
              style="padding: 15px 50px"
              @click="hideModal"
              >YES. I’D LIKE TO CONTINUE</b-button
            >
            <h3 class="mt-2 mhc-link-underline-grey" @click="stop">
              No, I’d like to stop now
            </h3>
          </div>
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import store from "@/store/index";
import { BRow, BCol, BModal, BProgress, BButton, BLink } from "bootstrap-vue";
import EventBus from '@/main';
import ProfessionalProfile from "../modals/ProfessionalProfile.vue"

export default {
    components: {
        BRow,
        BCol,
        BModal,
        BProgress,
        BButton,
        BLink,
        ProfessionalProfile
    },
    computed: {
        questionnaireCategory() {
            return store.state.formStoreModule.questionnaireCategory;
        },
        // Choosen questionnaire
        currentQuestionnaire() {
            return store.state.formStoreModule.currentQuestionnaire;
        },
        //Index of current question
        questionIndex() {
            return store.state.formStoreModule.questionIndex;
        },
    },
    methods: {
        openModal() {
          if(this.$refs['stop-modal'] != undefined) {
            this.$refs['stop-modal'].show()
          }
        },
        
        hideModal() {
          this.$refs['stop-modal'].hide()
        },

        openProfessionalProfileModal() {
          EventBus.$emit('openProfessionalProfileModal');
        },

        prev() {
          // When user go back from last questionnaire question (multiple selected)
          if(this.questionIndex +1 == this.currentQuestionnaire.questions.length) {
            EventBus.$emit('lastQuestionnaireAnswer')
          }

          if (this.currentQuestionnaire.questions.length > 0) {
            store.dispatch('formStoreModule/decrementQuestionIndex')
            store.commit("formStoreModule/setBackOptionFlag", true)
            store.commit("formStoreModule/setQuestionIndex", store.state.formStoreModule.questionIndex)
          }
          store.commit("formStoreModule/setQuestionnaireProgress", ((this.questionIndex / this.currentQuestionnaire.questions.length) * 100))

        },

        stop() {
          // Get questionnaire category id and category name
          let questionnaireCategoryId = store.state.formStoreModule.questionnaireCategoryId
          let questionnaireCategoryName = store.state.formStoreModule.questionnaireCategory

          // Redirect to form and pass params from above 
          this.$router.push('/form?qid='+questionnaireCategoryId+'&qname='+questionnaireCategoryName);
        },
    },
}
</script>