<template>
  <div class="p-0">
    <b-modal
      id="professional-profile-modal"
      ref="professional-profile-modal"
      hide-footer
      centered
      size="lg"
    >
        <template #modal-title>About {{professional.first_name}} {{professional.last_name}} - {{professional.qualifications}}</template>
        <div class="d-block py-2 px-1">
            <div class="text-center">
                <img :src="professional.profile_image_url" class="professional-modal-image rounded-image-100px rounded-circle" />
                <p class="mb-0 mt-05">{{professional.first_name}} {{professional.last_name}}</p>
                <small class="text-gray">{{professional.qualifications}}</small>
            </div>

            <p class="font-weight-normal mt-2">
              {{professional.profile_description}}
            </p>
        </div>
    </b-modal>
</div>
</template>

<script>
import { BLink, BModal, BButton } from "bootstrap-vue";
import EventBus from "../../main.js"

export default {
  components: {
    BLink,
    BModal,
    BButton
  },
  props: ['professional'],
  methods: {
    openModal() {
      // Show modal
      if(this.$refs['professional-profile-modal'] != undefined) {
        this.$refs['professional-profile-modal'].show()
      }
    },
  },
  mounted() {
    EventBus.$on('openProfessionalProfileModal', () => {
      this.openModal()
    });
  }
};
</script>

<style>
  .professional-modal-image {
      width: 130px;
      height: 130px;
      object-fit: cover;
  }
</style>
