<template>
  <div class="w-100">
    <div v-if="page == 0">
      <h3>About {{ currentQuestionnaire.title }}</h3>
      <hr class="mhc-divider ml-0" />
      <p>
        We all experience several life situations at anyone time that impact our short, medium or long-term mental health. 
        By selecting this mental health check, you recognize this as a situation that impacts your well-being and as a priority,
        you are commited to understanding, managing and improving your mental health and well-being.
      </p>

      <h3>Quick Tip</h3>
      <hr class="mhc-divider ml-0" />
      <p>
        This mental health check is private and confidential, so we encourage you to be as honest as possible while going 
        through this process so you get the most helpful information for you.
      </p>
      <p>
        Remember, these mental health checks are meant to be guides only and do not replace clinical assessments by a qualified mental health professional.
      </p>

      <h3>Getting Started</h3>
      <hr class="mhc-divider ml-0" />
      <b-form-checkbox
        id="checkbox-1"
        v-model="terms"
        name="checkbox-1"
        value="accepted"
      >
        <span class="text-dark-gray">I accept the <b-link class="mhc-link-underline" :to="{ name: 'terms-and-conditions' }"> Terms & Conditions </b-link> and <b-link class="mhc-link-underline" :to="{ name: 'privacy-policy' }"> Privacy Policy </b-link></span>
      </b-form-checkbox>
    </div>

    <div class="mt-2" v-if="page == 0">
      <b-button variant="primary" size="lg" :disabled="isDisabled" @click="next">
        Start My Mental Health Check
      </b-button>
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
import {
  BCard,
  BRow,
  BCardTitle,
  BCardText,
  BButton,
  BFormCheckbox,
  BLink,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCardTitle,
    BCardText,
    BButton,
    BFormCheckbox,
    BLink,
  },
  props: {
      page: {
          type: Number
      }
  },
  data() {
    return {
      terms: false
    };
  },
  computed: {
    questionnaireCategory() {
      return store.state.formStoreModule.questionnaireCategory;
    },
    // Choosen questionnaire
    currentQuestionnaire() {
      return store.state.formStoreModule.currentQuestionnaire;
    },
    //Index of current question
    questionIndex() {
      return store.state.formStoreModule.questionIndex;
    },
    isDisabled() {
      return !this.terms;
    },
  },
  methods: {
    // Start Survey
    next() {
      // scroll the user to the main container tag after reading the terms and conditions
      var elmnt = document.getElementsByClassName("questionnaire-placeholder")[0];
      elmnt.scrollIntoView();
            
      store.dispatch('formStoreModule/incrementPage')
      
      if (this.page == 2) {
        store.commit("formStoreModule/setShow", true);
      }

      let randomString = (Math.random() + 1).toString(36).substring(7);
      localStorage.setItem("random_string", randomString);

      // since the user started the questionnaire, send the request to mark it as started
      this.$http.post("/api/questionnaire/start", {
        "slug": this.$route.params.survey,
        "questionnaire_started_code": randomString
      })
        .then((res) => {
        })
        .catch((error) => {
        });

    },
  },
};
</script>
