<template>
  <b-overlay :show="show" rounded="sm" class="w-100">
    <div class="questionnaire-container pb-3 pt-2">
      <survey-header />

      <b-row
        class="justify-content-between no-gutters m-auto mb-2"
        style="max-width: 90%"
      >
        <div class="w-100">
          <b-progress
            :value="questionnaireProgress"
            max="100"
            variant="mhc-dark"
            height=".5rem"
            width="100%"
            class="mt-2 mb-3 questionnaire-progress"
          />
        </div>
      </b-row>

      <div class="questionBox">
        <pre-questionnaire :page="page" v-if="page == 0"></pre-questionnaire>

        <div v-if="page == 1">
          <div
            v-if="
              currentQuestionnaire.questions &&
              questionIndex < currentQuestionnaire.questions.length
            "
            v-bind:key="questionIndex"
          >
            <h3
              class="text-center m-auto question-title"
              style="max-width: 60ch"
            >
              {{ currentQuestionnaire.questions[questionIndex].question_text }}
            </h3>

            <div class="text-center pb-2">
              <p
                v-if="
                  currentQuestionnaire.questions[questionIndex]
                    .multiple_answers == 0
                "
              >
                Select one option
              </p>
              <p v-else>Select all options that apply</p>

              <hr class="mhc-divider m-auto" />
            </div>

            <b-form-checkbox-group
              :class="
                currentQuestionnaire.questions[questionIndex]
                  .is_scale === 0
                  ? 'multiple-answers'
                  : 'multiple-answers scale-number-container'
              "
              v-model="selected"
              name="multiple-answers"
            >
              <div
                v-for="(answer, index) in currentQuestionnaire.questions[
                  questionIndex
                ].answers"
                :key="index"
              >
                <div
                  class="questionContainer"
                  v-if="
                    currentQuestionnaire.questions[questionIndex]
                      .is_scale == 0
                  "
                >
                  <div class="d-flex">
                    <div class="optionContainer">
                      <div class="option">
                        <div
                          @click="selectOption(answer)"
                          v-if="
                            currentQuestionnaire.questions[questionIndex]
                              .multiple_answers == 0
                          "
                        >
                        <!-- Single answer -->
                          <span
                            :class="
                              final[questionIndex] &&
                              final[questionIndex].answers.includes(answer.id)
                                ? 'option-click bg-light-green'
                                : 'option-click'
                            "
                          >
                            {{ answer.answer_text }}
                          </span>
                        </div>
                        <div v-else>
                          <!-- Multiple answer -->
                          <b-form-checkbox
                            :value="answer"
                            :key="answer.id"
                            class="w-100 p-0 multiple-answer"
                            @change="setQuestionId(questionIndex)"
                          >
                            <span class="option-click">
                              {{ answer.answer_text }}
                              <!-- ovdee -->
                            </span>
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Scale answer-->
                <div v-else @click="selectOption(answer)">
                  <span
                    :class="
                      final[questionIndex] &&
                      final[questionIndex].answers.includes(answer.id)
                        ? 'scale-option bg-light-green'
                        : 'scale-option'
                    "
                  >
                    {{ answer.answer_text | removeScaleText }}
                  </span>
                </div>
                
              </div>

              <!-- No I haven’t option -->
              <div
                  class="questionContainer"
                  v-if="
                    currentQuestionnaire.questions[questionIndex].type.id == 3 || currentQuestionnaire.questions[questionIndex].type.id == 4
                  "
                >
                  <div class="d-flex">
                    <div class="optionContainer">
                      <div class="option">
                        <b-form-checkbox
                            class="w-100 p-0 multiple-answer"
                            @change="clickedNoAnswer(questionIndex)"
                          >
                            <span
                              v-if="selected.length == 0"
                              :class="answerSelected == true
                                  ? 'option-click bg-light-green'
                                  : 'option-click'
                              "
                            >
                              No I haven’t
                            </span>
                            <span
                              v-else
                              :class="selected.length == 1 && selected[0] == null
                                  ? 'option-click bg-light-green'
                                  : 'option-click'
                              "
                            >
                              No I haven’t
                            </span>
                          </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
            </b-form-checkbox-group>

            <div v-if="currentQuestionnaire.questions[questionIndex].is_scale === 1" class="scale-container mt-2 mt-sm-0">
              <div><small>1 = Strongly Disagree</small></div>
              <div><small>10 = Strongly Agree</small></div>
            </div>
            
            <div class="questionContainer">
              <div class="d-flex">
                <div class="optionContainer">
                  <footer class="questionFooter">
                    <nav
                      class="pagination"
                      role="navigation"
                      aria-label="pagination"
                    >
                      <div
                        class="option w-100"
                        v-if="
                          currentQuestionnaire.questions[questionIndex]
                            .multiple_answers == 1 && questionIndex + 1 < currentQuestionnaire.questions.length
                        "
                      >
                        <b-button
                          variant="primary"
                          block
                          class="option-click confirm-btn"
                          :disabled="answerSelected == false"
                          v-if="this.selected.length == 0"
                          v-on:click="
                            confirmMultipleAnswer(
                              currentQuestionnaire.questions[questionIndex]
                                .type,
                              currentQuestionnaire.questions[questionIndex].id
                            )
                          "
                        >
                          Confirm answers
                        </b-button>
                        <b-button
                          variant="primary"
                          block
                          class="option-click confirm-btn"
                          v-else
                          v-on:click="
                            confirmMultipleAnswer(
                              currentQuestionnaire.questions[questionIndex]
                                .type,
                              currentQuestionnaire.questions[questionIndex].id
                            )
                          "
                        >
                          Confirm answers
                        </b-button>
                      </div>
                    </nav>
                  </footer>
                </div>
              </div>
            </div>

            <div class="questionContainer">
              <div class="d-flex">
                <div class="optionContainer" v-if="currentQuestionnaire.subcategory.questionnairecategory.name == 'My mental health and my life situation(s)'">
                  <b-button
                    block
                    variant="primary"
                    size="lg"
                    :disabled="this.selected.length == 0 && this.answerSelected == false"
                    @click="submitResults(currentQuestionnaire.questions[questionIndex].type, currentQuestionnaire.questions[questionIndex].id)"
                    v-if="questionIndex + 1 == currentQuestionnaire.questions.length"
                    >Submit Results</b-button
                  >
                </div>

                <!-- Emotional type -->
                <div class="optionContainer" v-else>
                  <b-button
                    block
                    variant="primary"
                    size="lg"
                    :disabled="isDisabled"
                    @click="submitEmotionalResults(currentQuestionnaire.questions[questionIndex].type, currentQuestionnaire.questions[questionIndex].id)"
                    v-if="questionIndex + 1 == currentQuestionnaire.questions.length"
                    >Submit Results</b-button
                  >
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import EventBus from "../../main.js"
import store from "@/store/index";
import {
  BRow,
  BCol,
  BModal,
  BOverlay,
  BProgress,
  BButton,
  BLink,
  BFormCheckboxGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import PreQuestionnaire from "./PreQuestionnaire";
import SurveyHeader from "../../components/form/SurveyHeader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as utility from "../../util";

const userData = store.state.app.userData;

export default {
  components: {
    PreQuestionnaire,
    SurveyHeader,
    BRow,
    BCol,
    BModal,
    BOverlay,
    BProgress,
    BButton,
    BLink,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  data() {
    return {
      questionnaireSlug: "",
      userResponses: [],
      final: [],
      selected: [],
      answerSelected: false,
      submit: false,
      current_question_multiple: 0,
      options: [],
      question_id: 0,
      userPaymentStatus: "FREE"
    };
  },
  filters: {
    removeScaleText: function (value) {
      if (!value) return ''
      value = value.replace('Scale ', '')
      return value
    }
  },
  computed: {
    questionnaireProgress() {
      return store.state.formStoreModule.questionnaireProgress;
    },
    show() {
      return store.state.formStoreModule.show;
    },
    page() {
      return store.state.formStoreModule.page;
    },
    isDisabled() {
      return !this.submit;
    },
    questionnaireCategory() {
      return store.state.formStoreModule.questionnaireCategory;
    },
    // Choosen questionnaire
    currentQuestionnaire() {
      return store.state.formStoreModule.currentQuestionnaire;
    },
    //Index of current question
    questionIndex() {
      this.selected = [];

      if (this.final[store.state.formStoreModule.questionIndex] != undefined) {
        if (
          this.final[store.state.formStoreModule.questionIndex].answers.length >
            0 &&
          store.state.formStoreModule.backOptionFlag == true
        ) {
          this.selected = this.final[
            store.state.formStoreModule.questionIndex
          ].answers;
        }
      }

      return store.state.formStoreModule.questionIndex;
    },
  },
  methods: {
    clickedNoAnswer(question_id) {

      this.setQuestionId(question_id);

      // remove answers
      this.selected = [];      
      this.answerSelected = true;      
    },
    setQuestionId(question_id) {
      this.question_id = question_id;

      if(this.selected.length > 0 && this.selected[0] != null) {
        this.answerSelected = true;
      } else {
        this.answerSelected = false;
      }
      
      if(this.selected[0] == null) {
        this.selected.shift();
      }

    },
    confirmMultipleAnswer(questionType, questionId, answerSelected = null) {
      if(answerSelected == false) {
        this.nextQuestion();
        return false;
      }
      
      if(this.selected.length > 1) {
        if(this.selected[0] == null) {
          this.selected.shift();
        }
      }      
      this.$set(this.userResponses, this.questionIndex, this.selected);
      let answers = [];
      for (let i = 0; i < this.selected.length; i++) {
        answers.push(this.selected[i]);
      }

      let questions;
      // Check: If question type is C (ids 3 and 4)
      if (questionType.id == 3 || questionType.id == 4) {

        if (this.selected.length > 0) {
          
          if(this.selected.length == 1 && this.selected[0] == null) {
            questions = {
              question: questionId,
              answers: [null],
              selected: true
            };
          } else {
            questions = {
              //question: this.current_question_multiple,
              question: this.selected[0].pivot.question_id,
              answers: answers,
            };
          }
          
        } else {
          //If the answer is not selected
          questions = {
            question: questionId,
            answers: [null],
            selected: true
          };
        }
      } else {

        questions = {
          question: this.selected[0].pivot.question_id,
          answers: answers,
        };
      }

      this.selected = [];
      this.answerSelected = false

      this.$set(this.final, this.questionIndex, questions);
      this.nextQuestion();
    },
    submitResults(questionType, questionId) {
      this.confirmMultipleAnswer(questionType, questionId);

      let questions = this.final;

      let questionnaireResult = {
        questionnaire: this.currentQuestionnaire.id,
        questions,
        user_payment_status: this.userPaymentStatus
      };

      localStorage.setItem(
        "questionnaireResult",
        JSON.stringify(questionnaireResult)
      );
      localStorage.setItem("questionnaireSlug", this.questionnaireSlug);
      localStorage.setItem("questionnaireTitle", this.currentQuestionnaire.title);

      let userData2 = store.state.app.userData;
      var elmnt = document.getElementsByClassName(
        "questionnaire-placeholder"
      )[0];
      elmnt.scrollIntoView();

      if (
        userData2 != undefined &&
        userData2.accessToken != undefined &&
        userData2.accessToken != ""
      ) {
        if (this.currentQuestionnaire.questionnaire_flag == true) {
          store.commit("formStoreModule/setShow", true);
          this.$router.push("/score");
        } else {
          this.$router.push("/reminder");
        }
      } else {
        this.$router.push({
          name: "login",
        });
      }
    },
    submitEmotionalResults() {
      let questions = this.final;

      let questionnaireResult = {
        questionnaire: this.currentQuestionnaire.id,
        questions,
        user_payment_status: this.userPaymentStatus
      };

      localStorage.setItem(
        "questionnaireResult",
        JSON.stringify(questionnaireResult)
      );
      localStorage.setItem("questionnaireSlug", this.questionnaireSlug);
      localStorage.setItem("questionnaireTitle", this.currentQuestionnaire.title);

      let userData2 = store.state.app.userData;
      var elmnt = document.getElementsByClassName(
        "questionnaire-placeholder"
      )[0];
      elmnt.scrollIntoView();

      if (
        userData2 != undefined &&
        userData2.accessToken != undefined &&
        userData2.accessToken != ""
      ) {
        if (this.currentQuestionnaire.questionnaire_flag == true) {
          store.commit("formStoreModule/setShow", true);
          this.$router.push("/score");
        } else {
          this.$router.push("/reminder");
        }
      } else {
        this.$router.push({
          name: "login",
        });
      }
    },
    selectOption(answer) {
      this.$set(this.userResponses, this.questionIndex, answer);

      let questions = {
        question: this.userResponses[this.questionIndex].pivot.question_id,
        answers: [this.userResponses[this.questionIndex].id],
      };
      this.$set(this.final, this.questionIndex, questions);
      this.nextQuestion();
    },
    async nextQuestion() {
      // wait a bit before continuing. Else, user wil not get a visual indication of the button he clicked (especially on mobile view)
      await this.waitABit();

      if (this.questionIndex + 1 < this.currentQuestionnaire.questions.length) {
        // scroll the user to the main container tag after submitting the question
        // we do this only until the last question is selected and the submit results button is enabled
        //if(screen.width < 768) {
        var elmnt = document.getElementsByClassName(
          "questionnaire-placeholder"
        )[0];
        elmnt.scrollIntoView();
        //}

        store.dispatch("formStoreModule/incrementQuestionIndex");
        store.commit("formStoreModule/setQuestionIndex", this.questionIndex);

        // Update questionnaire progress
        store.commit(
          "formStoreModule/setQuestionnaireProgress",
          (this.questionIndex / this.currentQuestionnaire.questions.length) *
            100
        );

      } else {
        store.commit(
          "formStoreModule/setQuestionnaireProgress",
          ((this.questionIndex + 1) / this.currentQuestionnaire.questions.length) *
            100
        );
        this.submit = true;

      }
    },
    
  waitABit() {
    return new Promise((resolve, reject) => {
      setTimeout(()=> {
        resolve();        
      }, 50)     
    })},
  },
  created() {
    this.questionnaireSlug = this.$route.params.survey;
    let userData2 = store.state.app.userData;

    if (userData2 != "" && userData2 != undefined) {

      this.$http
        .get("/api/auth/questionnaire/slug/" + this.questionnaireSlug)
        .then((res) => {
          if (res != undefined) {
            
            store.commit("formStoreModule/setCurrentQuestionnaire", res.data);
            store.commit("formStoreModule/setShow", false);
  
            // Set user payment status
            this.userPaymentStatus = res.data.user_payment_status
  
            // check if we should go to the reminder of score page immediately since we have been redirected after google/facebook login
            let completed_flag = localStorage.getItem(
              "questionnaire_completed"
            );
            if (completed_flag == 1 || completed_flag == "1") {
              localStorage.removeItem("questionnaire_completed");
              if (this.currentQuestionnaire.questionnaire_flag == true) {
                store.commit("formStoreModule/setShow", true);
                this.$router.push("/score");
              } else {
                this.$router.push("/reminder");
              }
            }
          }
        })
        .catch((error) => {

          console.log(error)

          // if the API returns error 400 (not enough days passed since last submission)
          if(error.response.status == 400) {
              let daysLeft = 7 - error.response.data.days_since_last_result
              let dayText = (daysLeft > 1) ? "days" : "day"

              let message = `We recommend having a seven day rest period before taking this mental health check again. You will be able to take this "${error.response.data.title}" mental health check in ${utility.inWords(daysLeft)} ${dayText} time.`;
              
              if(error.response.data.questionnaire_type == "Emotions") {
                message = `We recommend having at least one day rest period before taking this mental health check again. You will be able to take this "${error.response.data.title}" mental health check tomorrow`;
              }
              
              let timeout = 10000

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: "AlertCircleIcon",
                  variant: "info",
                },
              },
              {
                timeout
              })

              // Redirect to form
              this.$router.push({ name: 'form' })
              return;
          }

          // if the user has not subscribed to the paid plan and has done 3 questionnaires
          if(error.response.status == 422) {
            // Show modal
            EventBus.$emit('showPricing', true)
          } else {
            console.log(error);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        });
    } else {
      this.$http
        .get("/api/auth/questionnaire/slug/" + this.questionnaireSlug)
        .then((res) => {
          if (res != undefined) {
            this.userPaymentStatus = res.data.user_payment_status
            store.commit("formStoreModule/setCurrentQuestionnaire", res.data);
            store.commit("formStoreModule/setShow", false);
          }
        })
        .catch((error) => {
          console.log(error);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    }
  },
  mounted() {
    EventBus.$on('lastQuestionnaireAnswer', () => {
      // Set questionType.id == 3 because last question is type 3 (C type)
      this.confirmMultipleAnswer({id: 3}, 3, this.answerSelected);
    })
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px;
  }
}
</style>
